import * as React from 'react'
import Layout from '../components/layout'

const Community = () => {
  const handleLearnMoreClick = () => {
    const formSection = document.getElementById('form-section')
    if (formSection) {
      formSection.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <div>
      {/* <Helmet>
        <title>Únete a Nuestra Comunidad de Aprendices de Francés</title>
        <meta
          name="description"
          content="¡Aprende francés de manera divertida y efectiva junto a franceses y otros hispanohablantes! Únete a nuestra comunidad y domina el francés con nuestros recursos y ambiente amigable."
        />
      </Helmet> */}
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-1" style={{marginBottom: '1rem'}}>
              ¡Aprende francés de manera divertida y efectiva!
            </h1>
            <h2 className="subtitle is-3" style={{margin: '2rem 0'}}>
              Únete a nuestra comunidad de aprendizaje y domina el francés con
              franceses y otros hispanohablantes.
            </h2>
            <button
              className="button is-large is-warning"
              onClick={handleLearnMoreClick}
              style={{marginBottom: '2rem'}}
            >
              Más Información
            </button>
          </div>
        </div>
      </section>
      <section className="section" id="form-section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div
                style={{
                  margin: 'auto',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <iframe
                  width="540"
                  height="100%"
                  src="https://e7b4fa4e.sibforms.com/serve/MUIFALpkOROtjeKSZLu39s6DNkp_s-QlljL2Q_m3m5s10o7kavAVDPBSuZosfutQrEa3dyd_K8GxA0gbNlHzuGuzr6yf9CuC3IAI48KkTVl4nK-7VHo-79jiItSuqRyQRa6wFc2h0DcuQAb5M5lQIXyyM28tJbKoKOxOLAbMx-BeMZakKgJ3pVWB1wDgtvBxjv7HLL_CGFURB-ZM"
                  frameborder="0"
                  scrolling="auto"
                  allowfullscreen
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '100%',
                    height: '100vh',
                    overflow: 'hidden',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Community
